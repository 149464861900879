import React, { useCallback, useContext, useEffect, useState } from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import { PluginInstallerContext } from "./PluginInstallerContext";
import { Alert, Button, Checkbox, Link, Paper, TextInput, Title, useToggleState } from "@yoast/ui-library";
import * as styles from "./styles.scss";
import classNames from "classnames";
import _debounce from "lodash/debounce";
import getEnv from "../../../../shared-frontend/functions/getEnv";
import FAQData from "./FAQData";

const messages = defineMessages( {
	formTitle: {
		id: "PluginInstaller.siteToInstallForm.title",
		defaultMessage: "Install {pluginName}",
	},
	formExplanation: {
		id: "PluginInstaller.siteToInstallForm.explanation",
		defaultMessage: "After you enter your WordPress site URL, " +
			"you'll be redirected to your WordPress admin page to grant permission. " +
			"This will generate an application password, allowing the app to perform tasks via the WordPress REST API.",
	},
	approvalExplanation: {
		id: "PluginInstaller.siteToInstallForm.approvalExplanation",
		defaultMessage: "Please grant the Yoast installer permission to access your site to install {pluginNameShorthand}.",
	},
	backupNotice: {
		id: "PluginInstaller.siteToInstallForm.backupNotice",
		defaultMessage: "We recommend to always create a backup of your site before installing a new plugin.",
	},
	siteUrlInputLabel: {
		id: "PluginInstaller.siteToInstallForm.siteUrlInputLabel",
		defaultMessage: "Your site's URL",
	},
	termsOfServiceLabel: {
		id: "PluginInstaller.siteToInstallForm.termsOfServiceLabel",
		defaultMessage: "I've read and accept the ",
	},
	submitButtonText: {
		id: "PluginInstaller.siteToInstallForm.submitButtonText",
		defaultMessage: "Install {pluginNameShorthand}",
	},
	installationFallbackNotification: {
		id: "PluginInstaller.siteToInstallForm.installationFallbackNotification",
		defaultMessage: "If you cannot install the plugin automatically, you can follow the link below to install it manually.",
	},
	redirectingToStartFlow: {
		id: "PluginInstaller.siteToInstallForm.redirectingToStartFlow",
		defaultMessage: "Redirecting to start the installation...",
	},
	redirectTakingTooLong: {
		id: "PluginInstaller.siteToInstallForm.redirectTakingTooLong",
		defaultMessage: "The redirect is taking too long, please click {here} to continue the installation.",
	},
	faqTitle: {
		id: "PluginInstaller.siteToInstallForm.faqTitle",
		defaultMessage: "FAQ",
	},
	installerNotWorking: {
		id: "pluginInstaller.messageBottom.installerNotWorking",
		defaultMessage: "Installer not working? You can follow the instructions {hereLink}.",
	},
} );

const pageExplainingManualInstallation = "https://yoast.com/help/installation-guide-for-wordpress-seo/";

const applicationPasswordCheckerServiceUrl = getEnv(
	"APPLICATION_PASSWORD_CHECKER_URL",
	"https://staging.application-password-support-checker.yoast.app/v1/route/",
);

const appName = "Yoast Plugin Installer";
// Should match the application password in the plugin installation service.
const appId   = "b29bdd38-1649-4a4f-bf08-1c507e70a092";

const applicationPasswordNotSuccessMapping = {
	COULD_NOT_FETCH_PAGE: "This site does not appear to be reachable.",
	RESPONSE_NOT_OK: "This site does not appear reachable, is not a WordPress site, or does not support installation via the application password.",
	NO_JSON_RESPONSE: "This site does not appear reachable, is not a WordPress site, or does not support installation via the application password.",
	SOMETHING_UNEXPECTED: "Something unexpected happened.",
	NO_APPLICATION_PASSWORD_ROUTE: "This site does not support installation via the application password.",
	INVALID_APPLICATION_PASSWORD_URL: "This site does not support installation via the application password.",
};

/* eslint-disable complexity */
/**
 * Checks if a URL string is missing the protocol.
 * @param {string} urlString The URL string to check.
 * @returns {boolean} Whether the URL string is missing the protocol.
 */
const urlStringIsMissingProtocol = ( urlString ) => {
	return urlString.match( /^\w+?:\/\// ) === null;
};

/**
 * Sanitizes the input by adding the https:// protocol if it is missing.
 * @param {string} input The user input.
 * @returns {string} The sanitized input.
 */
const sanitizeInput = ( input ) => {
	// if the input does not start with https://, prepend it.
	if ( input && urlStringIsMissingProtocol( input ) ) {
		return "https://" + input;
	}
	return input;
};

/**
 * A form to submit a site for the plugin install flow.
 *
 * @param {object} props The properties.
 * @returns {Element} The rendered component.
 * @constructor
 */
const SiteToInstallForm = ( props ) => {
	const { site, setSite, pluginToInstall } = useContext( PluginInstallerContext );

	const [ siteInput, setSiteInput ]                 = useState( site );
	const [ siteInputIsValid, , setSiteInputIsValid ] = useToggleState( false );

	const [ lastSiteInputError, setLastSiteInputError ] = useState( "" );
	const [ lastRequestError, setLastRequestError ]     = useState( "" );

	const [ isAcceptingTermsOfService, , setAcceptingTermsOfService ]                     = useToggleState( false );
	const [ isFetchingApplicationPasswordRoute, , setIsFetchingApplicationPasswordRoute ] = useToggleState( false );

	const [ isRedirecting, , , setIsRedirecting, resetRedirecting ] = useToggleState( false );
	const [ redirectUrl, setRedirectUrl ]                           = useState( "" );

	const userInputIsMissingProtocol = urlStringIsMissingProtocol( siteInput );
	const sanitizedSiteInput         = sanitizeInput( siteInput );

	/**
	 * Handles a failed request. Meaning a request that did not return a 200 status code.
	 * @param {Response} response The response object.
	 * @returns {Promise<void>} Nothing.
	 */
	const handleFailedRequest = async( response ) => {
		if ( response.status === 400 ) {
			const responseBody = await response.text();

			if ( responseBody.startsWith( "Unsupported URL" ) ) {
				setLastRequestError( "Unsupported URL" );
				return;
			}
		}
		if ( response.status === 429 ) {
			setLastRequestError( "Too many requests have been sent for this site today." );
			return;
		}

		setLastRequestError( "Something unexpected happened" );
	};

	/**
	 * Handles cases where the API was reached correctly, but the application password was not retrievable.
	 * @param {Object} responseContents The response contents.
	 * @returns {void} Nothing.
	 */
	const handleFailedSuccess = ( responseContents ) => {
		let message = applicationPasswordNotSuccessMapping[ responseContents.code ];

		if ( ! message ) {
			message = responseContents.message;
		}

		setLastRequestError( message );
	};

	/**
	 * Redirects the user to the application password page.
	 * @param {string} applicationPasswordRoute The route to the application password page.
	 * @returns {Promise<void>} Nothing.
	 */
	const redirectToApplicationPasswordPage = async( applicationPasswordRoute ) => {
		let route;
		try {
			route = new URL( applicationPasswordRoute );
		} catch {
			setLastRequestError( "Application password not available" );
			return;
		}


		// add query parameters to guide the application password process
		route.searchParams.append( "app_name", appName );
		route.searchParams.append( "app_id", appId );
		route.searchParams.append( "success_url", window.location.href );
		route.searchParams.append( "reject_url", pageExplainingManualInstallation );

		setIsRedirecting();
		window.location.href = route.href;

		// If the redirect is taking too long, set that in the state so we can show a message to the user.
		setTimeout( () => {
			setRedirectUrl( route.href );
		}, 4000 );
	};

	/**
	 * Fetches the application password for the site.
	 * @returns {Promise<void>} Nothing.
	 */
	const attemptTheAutomaticInstallFlow = async() => {
		setIsFetchingApplicationPasswordRoute( true );

		const applicationPasswordCheckerService = new URL( applicationPasswordCheckerServiceUrl );
		applicationPasswordCheckerService.searchParams.append( "url", sanitizedSiteInput );

		let response;

		try {
			response = await fetch( applicationPasswordCheckerService, { signal: AbortSignal.timeout( 15000 ) } );
		} catch ( error ) {
			console.error( error );
			setIsFetchingApplicationPasswordRoute( false );

			let feedback = "We were unable to install the plugin.";
			if ( error.message === "Failed to fetch" ) {
				feedback = feedback.concat( " The service is down." );
			}

			// Make the message complete.
			setLastRequestError( feedback );
			return;
		}

		setIsFetchingApplicationPasswordRoute( false );

		if ( ! response.ok ) {
			await handleFailedRequest( response );
			return;
		}

		const responseContents = await response.json();
		if ( ! responseContents ) {
			setLastRequestError( "We were unable to install the plugin" );
			return;
		}

		if ( responseContents.success === false ) {
			handleFailedSuccess( responseContents );
			return;
		}

		await redirectToApplicationPasswordPage( responseContents.route );
	};

	/**
	 * Checks if the site input is a valid URL.
	 * @param {string} input The site input.
	 * @returns {boolean} Whether the site input is a valid URL.
	 */
	const isASupportedSite = ( input ) => {
		let url;

		try {
			url = new URL( input );
		} catch {
			setLastSiteInputError( "Invalid URL" );
			return false;
		}

		// if the protocol is not https, the URL is invalid.
		if ( url.protocol !== "https:" ) {
			setLastSiteInputError( "The automatic installer requires that your site uses HTTPS." );
			return false;
		}

		return true;
	};

	/**
	 * Function to validate the site input.
	 * With debounce to prevent too many calls and with useCallback to prevent the debounce function from being recreated on re-render.
	 * @param {string} input The site input.
	 * @returns {void} Nothing.
	 */
	const checkSiteValidity = useCallback( _debounce( ( input ) => {
		setSiteInputIsValid( isASupportedSite( sanitizeInput( input ) ) );
	}, 500 ), [ setLastSiteInputError, setSiteInputIsValid ] );

	/**
	 * Handles the site input change.
	 * @param {string} input The site input.
	 * @returns {void} Nothing.
	 */
	const handleSiteInputChange = ( input ) => {
		setSiteInput( input.trim() );

		// Reset the last site input error, and evaluate the new site input.
		setLastSiteInputError( "" );
		checkSiteValidity( input );
	};

	/**
	 * Handles the form submission.
	 * @param {Event} event The form submission event.
	 * @returns {void} Nothing.
	 */
	const handleSubmit = ( event ) => {
		event.preventDefault();

		// As defensive coding, check the validity of the site input one last time.
		if ( ! isASupportedSite( sanitizedSiteInput ) ) {
			setSiteInputIsValid( false );
			return;
		}

		setLastRequestError( "" );

		// Set the site in the context, which should progress the plugin install flow.
		setSite( sanitizedSiteInput );
		attemptTheAutomaticInstallFlow();
	};

	// Check the initial site input on mount.
	useEffect( () => {
		checkSiteValidity( siteInput );
	}, [] );

	// Reset the redirecting state when back/forward cache is used.
	useEffect( () => {
		window.addEventListener( "pageshow", event => {
			if ( event.persisted ) {
				resetRedirecting();
			}
		} );
	}, [] );

	return (
		<>
			<Paper className={ classNames( styles.paper, styles.fullWidth ) }>
				<form className={ styles.siteForm } onSubmit={ handleSubmit }>
					<Title level={ 2 }>
						<FormattedMessage
							{ ...messages.formTitle }
							values={ { pluginName: pluginToInstall.displayName } }
						/>
					</Title>
					<div className={ styles.sectionSeparator }>
						<p>
							<em>
								<FormattedMessage { ...messages.backupNotice } />
							</em>
						</p>
						<br />
						<p>
							<FormattedMessage { ...messages.formExplanation } />
						</p>
						<br />
						<p>
							<FormattedMessage
								{ ...messages.approvalExplanation }
								values={ {
									pluginNameShorthand: <span className={ classNames( styles.pinkText, styles.boldText ) }>
										{ pluginToInstall.displayNameShorthand }
									</span>,
									styledApprove: <span className={ styles.boldText }>approve</span>,
								} }
							/>
						</p>
					</div>
					<div className={ classNames( styles.sectionSeparator, "yst-mb-4" ) } id="siteInputSection">
						<label htmlFor="site"><strong><FormattedMessage { ...messages.siteUrlInputLabel } /></strong></label>
						<div className={ styles.siteInputArea }>
							{ userInputIsMissingProtocol && <span className={ styles.inputPrefix }>https://</span> }
							<TextInput
								id="site"
								name="site"
								className={ classNames(
									styles.siteInputField,
									{ [ styles.withPrefix ]: userInputIsMissingProtocol },
								) }
								onChange={ ( event ) => handleSiteInputChange( event.target.value ) }
								placeholder="yoast.com"
								defaultValue={ siteInput }
							/>
						</div>
						{ siteInput && ( ! siteInputIsValid ) && lastSiteInputError &&
							<p className={ styles.errorText }>{ lastSiteInputError }</p> }
					</div>
					<div id="termsOfServiceSection" className={ styles.singleRow }>
						<Checkbox
							id="termsOfService"
							label={ props.intl.formatMessage( messages.termsOfServiceLabel ) }
							aria-label={ props.intl.formatMessage( messages.termsOfServiceLabel ) + "Terms and conditions" }
							name="termsOfService"
							value="false"
							onChange={ ( event ) => setAcceptingTermsOfService( event.target.checked ) }
						/>
						<Link className={ styles.termsOfServiceLink } href="https://yoast.com/terms-of-service" target="_blank">
							Terms of service
						</Link>
					</div>
					<br />
					<Button
						type="submit"
						disabled={ ( ! siteInputIsValid ) || ( ! isAcceptingTermsOfService ) || isFetchingApplicationPasswordRoute }
					>
						<FormattedMessage
							{ ...messages.submitButtonText }
							values={ { pluginNameShorthand: pluginToInstall.displayNameShorthand } }
						/>
					</Button>
					{ isFetchingApplicationPasswordRoute && <p>Checking the site...</p> }
					{ isRedirecting && <div>
						<p>
							<FormattedMessage { ...messages.redirectingToStartFlow } />
						</p>
						{ redirectUrl &&
							<p>
								<FormattedMessage
									{ ...messages.redirectTakingTooLong }
									values={ {
										here: <a href={ redirectUrl }>here</a>,
									} }
								/>
							</p>
						}
					</div>
					}
					{ lastRequestError &&
						<Alert role="alert" variant="error" className={ "yst-mt-4" }>
							<span> { lastRequestError } </span>
						</Alert>

					}
				</form>
			</Paper>
			<Paper className={ classNames( styles.paper, styles.fullWidth ) }>
				<p className={ "yst-p-5" }>
					<FormattedMessage
						{ ...messages.installerNotWorking }
						values={ {
							hereLink: <a href={ pageExplainingManualInstallation }>here</a>,
						} }
					/>
				</p>
			</Paper>
			<Paper className={ classNames( styles.paper, styles.fullWidth ) }>
				<div className={ classNames( styles.siteForm ) }>
					<Title size="4" className={ "yst-pb-2" }><FormattedMessage { ...messages.faqTitle } /></Title>
					{ FAQData.map( entry => {
						return (
							<>
								<details key={ entry.question.defaultMessage } className={ classNames( "yst-my-2", styles.faqEntry ) }>
									<summary>
										<div className={ classNames( "yst-text-base", "yst-pl-4", "yst-cursor-pointer" ) }>
											{ <FormattedMessage { ...entry.question } /> }
										</div>
									</summary>
									<div className={ classNames( "yst-pl-4", "yst-mt-3", styles.answer ) }>
										{ entry.answer }
									</div>
								</details>
							</>
						);
					} ) }
				</div>
			</Paper>
		</>
	);
};

/* eslint-enable */

SiteToInstallForm.propTypes = {
	intl: intlShape.isRequired,
};

export default injectIntl( SiteToInstallForm );
