const installablePlugins = [
	{
		slug: "yoast-seo-for-wordpress-free",
		displayName: "Yoast SEO for WordPress",
		displayNameShorthand: "Yoast SEO",
		isDefaultSelection: true,
	},
];

export default installablePlugins;
