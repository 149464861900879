/* eslint-disable max-len */

import { defineMessages } from "react-intl";
import React from "react";

const messages = defineMessages( {
	informationRetrievalQuestion: {
		id: "pluginInstaller.faq.informationRetrieval",
		defaultMessage: "What information does the Yoast installer retrieve?",
	},
	howLongQuestion: {
		id: "pluginInstaller.faq.howLong",
		defaultMessage: "How long will the Yoast installer have access to my site and what will it do?",
	},
	passwordStorageQuestion: {
		id: "pluginInstaller.faq.passwordStorage",
		defaultMessage: "Does Yoast store the password and if yes, where and for how long?",
	},
	securityMeasuresQuestion: {
		id: "pluginInstaller.faq.securityMeasures",
		defaultMessage: "What security measures did Yoast take to ensure a safe installation?",
	},
	whatTasksQuestion: {
		id: "pluginInstaller.faq.whatTasks",
		defaultMessage: "What tasks does the Yoast installer perform to install the Yoast plugin automatically?",
	},
} );

// Answers to the FAQ questions. Not as an intl message because they include can include html, and getting that to look proper is a pain.
const answers = {
	informationRetrievalAnswer: (
		<p>
			{ /* eslint-disable-next-line react/no-unescaped-entities */ }
			It retrieves the logged in user's email address. This information is stored temporarily only for the duration of the installation.
		</p>
	),
	howLongAnswer: (
		<p>
			The Yoast installer has the same access as the user running it, but only for the short time needed to install Yoast (usually a few seconds). The installer automatically adds the Yoast plugin to your website and instantly revokes its own access.
		</p>
	),
	passwordStorageAnswer: (
		<p>
			We do not store any password in any way.
		</p>
	),
	securityMeasuresAnswer: (
		<p>
			<ol>
				<li>We keep a permanent record of every request we send to the site using their credentials. This record includes:
					<ul>
						<li>The URL we used</li>
						<li>The request method (e.g., GET, POST, PUT, and DELETE)</li>
						<li>When the request started</li>
						<li>When the request is finished</li>
						<li>The HTTP status code returned from the website</li>
						<li>Error messages and response bodies.</li>
					</ul>
				</li>
				<li>Users’ credentials are used solely to retrieve the email address associated with the account on the user’s site. If the installation is aborted or the credentials cannot be revoked automatically, we will email the users advising manual removal of the credentials.</li>
				<li>We prevent any application password from being logged or stored on our infrastructure.</li>
				<li>We strictly request the necessary information from the user&apos;s site for the installation.</li>
				<li>If an error is detected during the installation/activation process, the plugin will automatically deactivate to prevent any disruption to the site.</li>
				<li>The Yoast installer is exclusively employed to install Yoast plugins</li>
			</ol>
		</p>
	),
	whatTasksAnswer: (
		<p>
			<ol>
				<li>Get access using the provided credentials.</li>
				<li>Get the contact email address (store along with the site URL for about 15 minutes)</li>
				<li>Complete the installation of the Yoast plugin.</li>
				<li>Revoke our access typically within five seconds under normal circumstances. This might take longer on slower sites.</li>
				<li>Remove the stored email address and site URL. If that fails, the user will receive an email asking them to revoke our access manually, then we delete the stored information.</li>
				<li>Once the above steps are finished or the installer tab is closed, we delete all the stored credentials.</li>
			</ol>
		</p>
	),
};

const FAQData  = [
	{
		question: messages.informationRetrievalQuestion,
		answer: answers.informationRetrievalAnswer,
	},
	{
		question: messages.howLongQuestion,
		answer: answers.howLongAnswer,
	},
	{
		question: messages.passwordStorageQuestion,
		answer: answers.passwordStorageAnswer,
	},
	{
		question: messages.securityMeasuresQuestion,
		answer: answers.securityMeasuresAnswer,
	},
	{
		question: messages.whatTasksQuestion,
		answer: answers.whatTasksAnswer,
	},
];

export default FAQData;
